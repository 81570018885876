import React, { useState } from 'react';
import { Box, Button, Dialog, Tabs, Tab, Typography, Grid2 } from '@mui/material';

const GuestInfoPopup = ({ open, handleClose, guestInfo }) => {
  const [activeTab, setActiveTab] = useState(0);
  console.log({ guestInfo });

  return (
    <div>
      {/* <Button variant="contained" onClick={handleOpen}>
        Open Popup
      </Button> */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Info
          </Typography>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Phone Number
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.phoneNumber}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                First Name
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.firstName}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Last Name
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.lastName}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Table
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.seatingArrangement}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Is Attending
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.isAttending ? 'YES' : 'No'}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Has responded?
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.hasResponded ? 'YES' : 'No'}
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom>
                Has Plus One?
              </Typography>
            </Grid2>
            <Grid2 item container sx={{ padding: 1 }} direction="row" xs={12}>
              <Typography variant="text" gutterBottom fontWeight={'bolder'}>
                {guestInfo.hasPlusOne ? 'YES' : 'No'}
              </Typography>
            </Grid2>
          </Grid2>

          <Box sx={{ textAlign: 'right', marginTop: 2 }}>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default GuestInfoPopup;
