import React, { useEffect } from 'react';
import { Grid2, Box, Typography, Card, CardContent, CardActions, Button, Autocomplete, TextField } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'; // Using Recharts for chart
import { getAllGuest } from '../../../APIs/eventApi';
import { useState } from 'react';
import GuestInfoPopup from './GuestInfoPopup';

const Dashboard = ({ eventId }) => {
  const [tableData, setTableData] = useState([]);
  const [guestComing, setGuestComing] = useState([]);
  const [guestResponded, setGuestResponded] = useState([]);
  const [openGuestInvitation, setOpenGuestInvitation] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState({});

  const getGuest = async () => {
    await getAllGuest(eventId).then((res) => {
      setTableData(res);

      //   Guest that are coming
      const guestComingCount = res.filter((guest) => guest.isAttending === true).length;
      const guestNotComingCount = res.length - guestComingCount;

      setGuestComing([
        {
          name: 'Guest Coming',
          value: guestComingCount,
        },
        {
          name: 'Guest Not Coming',
          value: guestNotComingCount,
        },
      ]);

      // Guest that responded
      const guestRespCount = res.filter((guest) => guest.hasResponded === true).length;
      const guestNotRespCount = res.length - guestRespCount;

      setGuestResponded([
        {
          name: 'Guest Which Responded',
          value: guestRespCount,
        },
        {
          name: 'Guest Which Did Not Respond',
          value: guestNotRespCount,
        },
      ]);
    });
  };

  const handleCloseDetail = () => setOpenGuestInvitation(false);

  const handleGuestSelected = (event, newValue) => {
    setSelectedGuest(tableData.filter((data) => data.phoneNumber === newValue)[0]);
    setOpenGuestInvitation(true);
  };

  useEffect(() => {
    getGuest();
  }, []);

  return (
    <Grid2 container spacing={3} sx={{ padding: 3 }} direction="column">
      {openGuestInvitation && (
        <GuestInfoPopup open={openGuestInvitation} handleClose={handleCloseDetail} guestInfo={selectedGuest} />
      )}
      <Grid2 item xs={12}>
        <Typography variant="h3" color="textPrimary" fontWeight={'bold'} gutterBottom>
          Dashboard
        </Typography>
      </Grid2>
      {/* Top Box (Count Metric) */}
      <Grid2 container spacing={3} sx={{ padding: 3 }} direction="row" xs={12}>
        <Grid2 item xs={6} width={'30%'}>
          <Card sx={{ boxShadow: 3, borderRadius: 2, backgroundColor: '#f4f6f8' }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Guest Count
              </Typography>
              <Typography variant="h4" color="primary">
                {tableData.length}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary">
                View Details
              </Button>
            </CardActions>
          </Card>
        </Grid2>
        <Grid2 item xs={6} width={'30%'}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Guest Presence Count
              </Typography>
              <PieChart width={300} height={300}>
                <Pie
                  data={guestComing}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {guestComing?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={['#FF8042', '#00C49F', '#FFBB28', '#0088FE'][index % 4]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={6} width={'30%'}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Guest Who Responded
              </Typography>
              <PieChart width={300} height={300}>
                <Pie
                  data={guestResponded}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {guestComing?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={['#0088FE', '#FFBB28'][index % 4]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Grid2 item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          sx={{ backgroundColor: '#fff' }}
          freeSolo
          options={tableData.map((guest) => guest.phoneNumber)}
          renderInput={(params) => <TextField {...params} label="Numero De Telephone" />}
          onChange={handleGuestSelected}
        />
      </Grid2>
      {/* Second Box (Table) */}
      <Grid2 item xs={12}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              Guest List
            </Typography>
            <Box sx={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>First Name</th>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Last Name</th>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Phone Number</th>
                    {/* <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Email</th> */}
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Attending</th>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Has Plus One</th>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Responded</th>
                    <th style={{ borderBottom: '2px solid #ccc', padding: '8px' }}>Will Receive Updates</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row) => (
                    <tr key={row._id}>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>{row.firstName}</td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>{row.lastName}</td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>{row.phoneNumber}</td>
                      {/* <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>{row.email}</td> */}
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                        {row.isAttending ? 'YES' : 'NO'}
                      </td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                        {row.canHavePlusOne ? 'YES' : 'NO'}
                      </td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                        {row.hasResponded ? 'YES' : 'NO'}
                      </td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>
                        {row.willReceiveUpdate ? 'YES' : 'NO'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2>
  );
};

export default Dashboard;
